import React from "react"
import { Link } from "gatsby"
import Parse from "html-react-parser"

import Image from "../../image"

// Helpers
import removeDomaine from "../../../helpers/url.helpers"

const BlockServiceWrapper = ({ children, data }) => {
   if (data?.button?.url) {
      return (
         <Link to={removeDomaine(data.button.url)} title={data.button.title}>
            {children}
         </Link>
      )
   }

   return <div>{children}</div>
}

const BlockService = ({ oBlockService }) => {
   if (!oBlockService || !oBlockService.title || !oBlockService.title === "") {
      return null
   }

   return (
      <article className="block-service">
         <BlockServiceWrapper data={oBlockService}>
            <div className="block-service__thumbnail">
               <div className="inner-box">
                  <Image imageID={oBlockService.image} mSize={`small`} />
               </div>
            </div>

            <div className="block-service__description">
               <h2 className="background-line">
                  <span>{oBlockService.title}</span>
               </h2>

               {Parse(`<p>${oBlockService.text}</p>`)}

               {oBlockService.button && (
                  <span className="button--container">
                     <span className="button">
                        {oBlockService.button.title}
                     </span>
                  </span>
               )}
            </div>
         </BlockServiceWrapper>
      </article>
   )
}

export default BlockService
