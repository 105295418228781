import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { JsonLd } from "react-schemaorg";

export function SchemaOrg() {

   const site = useStaticQuery(
      graphql`
         query {
            datas: allWordpressAcfOptions {
               edges {
                  node {
                     informations_sites {
                        ep_instagram
                        ep_twitter
                        ep_facebook
                        ep_phone
                        ep_address {
                           address_cp
                           address_number
                           address_street
                           address_town
                        }
                        ep_range_price
                     }
                  }
               }
            }
            siteInfo: wordpressSiteMetadata {
               name
            }
         }
      `
   )

   let socialArray = [];
   if(site.datas.edges[0].node.informations_sites.ep_facebook) socialArray.push(site.datas.edges[0].node.informations_sites.ep_facebook)
   if(site.datas.edges[0].node.informations_sites.ep_twitter) socialArray.push(site.datas.edges[0].node.informations_sites.ep_twitter)
   if(site.datas.edges[0].node.informations_sites.ep_instagram) socialArray.push(site.datas.edges[0].node.informations_sites.ep_instagram)
   if(site.datas.edges[0].node.informations_sites.ep_linkedin) socialArray.push(site.datas.edges[0].node.informations_sites.ep_linkedin)

   let priceDevis = "€"

   
   return <JsonLd
      item={{
         "@context": "http://schema.org",
         "@type": "Restaurant",
         "address": {
           "@type": "PostalAddress",
           "addressLocality": site.datas.edges[0].node.informations_sites.ep_address.address_town,
           "addressRegion": "BE",
           "postalCode": site.datas.edges[0].node.informations_sites.ep_address.address_cp,
           "streetAddress": `${site.datas.edges[0].node.informations_sites.ep_address.address_street} ${site.datas.edges[0].node.informations_sites.ep_address.address_number}`
         },
         "name": site.siteInfo.name,
         "priceRange": priceDevis.repeat(site.datas.edges[0].node.informations_sites.ep_range_price),
         "telephone": site.datas.edges[0].node.informations_sites.ep_phone,
         "url": "https://lepicerietournai.be",
         "sameAs": socialArray
      }}/>;
}

export default SchemaOrg
