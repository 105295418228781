import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ WPPageID }) => {
   const site = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                 siteUrl
               }
            }
            datas: allWordpressPage {
               edges {
                  node {
                     wordpress_id
                     title
                     excerpt
                     yoast_meta {
                        seo_image
                        seo_title
                        seo_description
                     }
                  }
               }
            }
            medias: allWordpressWpMedia {
               edges {
                  node {
                     wordpress_id
                     alt_text
                     localFile {
                        publicURL
                     }
                  }
               }
            }
         }
      `
   )

   

   let currentMeta = site.datas.edges.find(meta => {
      if(parseInt(meta.node.wordpress_id) === parseInt(WPPageID))
         return meta
      else
         return false
   })

   

   let yoastMeta = currentMeta.node.yoast_meta
   let metas = []

   if(yoastMeta.seo_title) {
      Array.prototype.push.apply(metas, [
         {
            name: 'twitter:title',
            content: yoastMeta.seo_title
         },
         {
            property: 'og:title',
            content: yoastMeta.seo_title
         }
      ])
   }

   if(yoastMeta.seo_description) {
      Array.prototype.push.apply(metas, [
         {
            name: 'description',
            content: yoastMeta.seo_description
         },
         {
            name: 'twitter:description',
            content: yoastMeta.seo_description
         },
         {
            property: 'og:description',
            content: yoastMeta.seo_description
         }
      ])
   }

   if(yoastMeta.seo_image) {

      let currentImage = site.medias.edges.find(media => {
         if(media.node.wordpress_id === yoastMeta.seo_image)
            return media
         else
            return false
      })

      if(currentImage) {
         Array.prototype.push.apply(metas, [
            {
               name: 'twitter:card',
               content: 'summary_large_image'
            },
            {
               name: 'twitter:image',
               content: site.site.siteMetadata.siteUrl+currentImage.node.localFile.publicURL
            },
            {
               property: 'og:image',
               content: site.site.siteMetadata.siteUrl+currentImage.node.localFile.publicURL
            }
         ])
      }
   }

   let metaTitle = yoastMeta.seo_title ? yoastMeta.seo_title : currentMeta.node.title

   return (
      <Helmet
         htmlAttributes={{
            lang: 'fr',
         }}
         title={metaTitle}
         meta={metas.concat(metas)}
      />
   )
}

export default SEO
