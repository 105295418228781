import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SchemaOrg from '../components/schemaOrg'

import HeroFrontpage from '../components/elements/10-hero/HeroFrontpage'
import BlockService from '../components/elements/02-blocks/BlockService'
import BlockHours from '../components/elements/02-blocks/BlockHours'
import SectionAbout from '../components/elements/03-sections/SectionAbout'

const IndexPage = ({ data }) => {

   let parsedData = data.wordpressPage.acf
   
   let oHeroFrontpage = {
      title: parsedData.home_hero.home_hero_title,
      subtitle: parsedData.home_hero.home_hero_subtitle,
      image: parsedData.home_hero.home_hero_image
   }

   let oBlockServiceFirst = {
      title: parsedData.home_two_columns_service.home_two_columns_service_column_one.home_two_columns_service_column_one_title,
      text: parsedData.home_two_columns_service.home_two_columns_service_column_one.home_two_columns_service_column_one_text,
      image: parsedData.home_two_columns_service.home_two_columns_service_column_one.home_two_columns_service_column_one_image,
      button: {
         title: parsedData.home_two_columns_service.home_two_columns_service_column_one.home_two_columns_service_column_one_button.title,
         url: parsedData.home_two_columns_service.home_two_columns_service_column_one.home_two_columns_service_column_one_button.url
      }
   }

   let oBlockServiceSecond = {
      title: parsedData.home_two_columns_service.home_two_columns_service_column_two.home_two_columns_service_column_two_title,
      text: parsedData.home_two_columns_service.home_two_columns_service_column_two.home_two_columns_service_column_two_text,
      image: parsedData.home_two_columns_service.home_two_columns_service_column_two.home_two_columns_service_column_two_image,
      button: {
         title: parsedData.home_two_columns_service.home_two_columns_service_column_two.home_two_columns_service_column_two_button.title,
         url: parsedData.home_two_columns_service.home_two_columns_service_column_two.home_two_columns_service_column_two_button.url
      }
   }

   let oSectionAbout = {
      title: parsedData.home_three_columns_about.home_three_columns_about_title,
      column_one: parsedData.home_three_columns_about.home_three_columns_about_column_one,
      column_three: {
         image_one: parsedData.home_three_columns_about.home_three_columns_about_column_three.home_three_columns_about_column_three_image_one,
         image_two: parsedData.home_three_columns_about.home_three_columns_about_column_three.home_three_columns_about_column_three_image_two
      }
   }

   return(
      <Layout>
         <SEO WPPageID={data.wordpressPage.wordpress_id} />

         <div className="site page page--frontpage">

            <BlockHours />

            <main id="main">
               <HeroFrontpage oHero={oHeroFrontpage} />

               <div className="container">
                  <div className="grid grid--two">
                     <BlockService oBlockService={oBlockServiceFirst} />
                     <BlockService oBlockService={oBlockServiceSecond} />
                  </div>
               </div>
            </main>

            <SectionAbout oSectionAbout={oSectionAbout} />

            <hr className="background-line" />
         </div>
         
         <SchemaOrg />
         
      </Layout>
   )
}

export default IndexPage

export const query = graphql`
   query {
      wordpressPage(slug: {eq: "accueil"}) {
         wordpress_id
         acf {
            home_hero {
              home_hero_image
              home_hero_subtitle
              home_hero_title
            }
            home_three_columns_about {
              home_three_columns_about_column_one
              home_three_columns_about_column_three {
                home_three_columns_about_column_three_image_one
                home_three_columns_about_column_three_image_two
              }
              home_three_columns_about_title
            }
            home_two_columns_service {
              home_two_columns_service_column_one {
                home_two_columns_service_column_one_button {
                  target
                  title
                  url
                }
                home_two_columns_service_column_one_text
                home_two_columns_service_column_one_image
                home_two_columns_service_column_one_title
              }
              home_two_columns_service_column_two {
                home_two_columns_service_column_two_button {
                  target
                  title
                  url
                }
                  home_two_columns_service_column_two_image
                  home_two_columns_service_column_two_text
                  home_two_columns_service_column_two_title
              }
            }
         }
      }
   }
`
