import React from 'react'
import Image from '../../image'

const HeroFrontpage = ({ oHero }) => {

   return(
      <div className="hero hero--frontpage container">
         <div className="hero--frontpage__image">
            <div className="inner-box">
               <Image imageID={oHero.image} />
            </div>
         </div>

         <div className="hero--frontpage__description">
            <h1 className="with-subtitle">
               <span className="visually-hidden">L'épicerie, restaurant de </span>{oHero.title}<span className="visually-hidden">à</span>
               <span className="subtitle">{oHero.subtitle}</span>
            </h1>
         </div>
      </div>
   )
}

export default HeroFrontpage

