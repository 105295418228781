import React from 'react'
import Parse from 'html-react-parser'
import Image from '../../image'

const SectionAbout = ({ oSectionAbout }) => {
   return (
      <aside className="section section--about container">
         <h2 className="background-line"><span>{oSectionAbout.title}</span></h2>

         <div className="section--about__content">

            <div className="section--about__content-description">
               {Parse(oSectionAbout.column_one)}
            </div>

            <div className="section--about__content-images">
               <div className="outer-box">
                  <div className="inner-box">
                     <Image imageID={oSectionAbout.column_three.image_one} mSize={`small`} />
                  </div>
               </div>
               <div className="outer-box">
                  <div className="inner-box">
                     <Image imageID={oSectionAbout.column_three.image_two} mSize={`small`} />
                  </div>
               </div>
            </div>
         </div>
      </aside>
   )
}

export default SectionAbout